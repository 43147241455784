import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/stable';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App'; 
import { runWithAdal } from 'react-adal';
import { authContext, adalConfig } from './adalConfig';

export const DO_NOT_LOGIN = false;
const redirectUrl = adalConfig.postLogoutRedirectUri + '/'
const resource = authContext.config.loginResource;
const token = authContext.getCachedToken(resource);
const user = authContext.getCachedUser();

const app = () => {
    ReactDOM.render(<App />, document.querySelector('#demo'));
};

if (!token || !user) {
    if (window.location.href !== redirectUrl) {
      if (!authContext.isCallback(window.location.hash)) {
        window.location.href === redirectUrl;
      } else {
        runWithAdal(authContext, app, DO_NOT_LOGIN);
      }
    } else {
      runWithAdal(authContext, app, DO_NOT_LOGIN);
    }
} else {
  runWithAdal(authContext, app, DO_NOT_LOGIN);
}