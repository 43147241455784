import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';


export const adalConfig = {
  tenant: process.env.environment.tenant,
  clientId: process.env.environment.clientId,
  postLogoutRedirectUri: window.location.origin,
  endpoints: {
    api: process.env.environment.api
  },
  cacheLocation: 'localStorage',
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);

export const getToken = authContext.getCachedToken(adalConfig.clientId);


setTimeout(() => {
  authContext.logOut();
}, 3000000);
